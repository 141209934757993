.image_banner_container {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1rem;
}

.image_banner_direction_normal {
    flex-direction: row;
}

.image_banner_direction_reverse {
    flex-direction: row-reverse;
}

.image_banner_container .title-subtitle-container {
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
    /* padding: 24px; */
}

.image_banner_container .image-view .imageview--half {
    width: auto;
    max-width: 300px;
}

.image_banner_container h1 {
    font-size: 1.8rem;
    text-align: start;
}

.image_banner_container p {
    font-size: 1rem;
    text-align: start;
}

@media screen and (max-width: 640px) {
    .image_banner_container {
        flex-direction: column;
    }

    .image_banner_container .image-view .imageview--half {
        width: 80%;
    }
}