.contacts-hero-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 4rem;
    gap: 2rem;
}

.contacts-hero-container .image-container {
    /* justify-content: center; */
    margin: 1rem;
    /* width: 100%; */
}

.contacts-hero-container .image-wrapper {
    /* overflow: hidden; */
    /* width: 30%; */
    display: flex;
    justify-items: center;
}

/* .contacts-hero-container  */
.contacts-hero-container .image-wrapper-item {
    position: relative;
    /* top: 0;
    right: 0;
    bottom: 0;
    left: 0; */
    display: block;
    /* width: auto; */
    /* height: 30vh; */
    width: 100%;
    max-width: 500px;
    /* width: 800px; */
    /* height: 500px; */
    /* max-height: 80%; */
    object-fit: cover;
    transition: all 0.2s linear;
    border-radius: 1rem;
  }

.contacts-hero-container .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contacts-hero-container .inputs-container {
    /* margin: 1rem; */
    max-width: 500px;
}

.contacts-hero-container .inputs-banner {
    /* margin: 1rem; */
    display: flex;
    flex-direction: column;
    align-items: start;
}

.contacts-hero-container h1 {
    /* font-size: 40px; */
    color: #030303;
}

.contacts-hero-container p {
    /* font-size: 20px; */
    margin-top: 1rem;
    color: #030303;
}

/* .contacts-hero-container .inputs-textfields {
    margin: 0 1rem;
} */

.contacts-hero-container .inputs-submitbtn {
    margin: 0 1rem 1rem 1rem;
    max-width: 20rem;
}

.contacts-hero-container .inputs-submitbtn button {
    width: 100%;
}

@media screen and (max-width: 640px) {
    .contacts-hero-container .image-container {
        display: none;
    }
}

/* @media screen and (min-width: 961) {
    .contacts-hero-container .image-container {
        display: flex;
    }
} */