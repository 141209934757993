.input-field-container {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    align-items: start;
}

.input-field-container .input-field-title {
    display: flex;
}

.input-field-container .input-field-title > p {
    font-family: 'Trebuchet MS', 'Lucide SansUnicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 1rem;
    color: #030303;
    margin: 0;
}

.input-field-container .input-field-wrapper {
    margin: 0.65rem 0;
    width: 100%;
}

.input-field-container .input-field-item {
    padding: 1rem 0.65rem;
    border-radius: 0.65rem;
    margin-right: 0.5rem;
    outline: none;
    border: none;
    font-size: 1rem;
    /* margin-bottom: 1rem; */
    border: 1px solid #fff;
    width: 100%;
    max-width: 400px;
}

/* .input-field-container .input-field-title {
    margin-top: 18px;
} */

.input-field-container textarea {
    resize: none;
}