.image-view {
    display: flex;
}

.image-wrapper {
    display: flex;
}

.image-view .image-wrapper-item {
    position: relative;
    /* top: 0;
    right: 0;
    bottom: 0;
    left: 0; */
    display: block;
    /* width: 50%; */
    /* height: 30vh; */
    /* max-width: 30%; */
    /* width: 800px; */
    /* height: 500px; */
    /* max-height: 80%; */
    object-fit: cover;
    transition: all 0.2s linear;
    border-radius: 22px;
}

.image-view .imageview--half {
    width: 40%;    
}

.image-view .imageview--full {
    width: 80%;
}