.footer-container {
    background-color: #ededed;
    padding: 0 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.footer-container hr {
    border: none;
    border-top: 1px solid #D9D9D9;
    margin: 0 2rem 0 2rem;
    
}

.footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
}

.footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucide SansUnicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
}

.footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
}

.footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
}

.footer-links {
    width: 100%;
    /* max-width: 1000px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-link-wrapper {
    padding: 0 4rem 0 4rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 28px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
}

.footer-link-items h2 {
    margin-bottom: 16px;
}

.footer-link-items > h2 {
    color: #262626;
}


.footer-link-items a {
    color: #262626;
    text-decoration: none;
    margin-bottom: 8px;
}

.footer-link-items a:hover {
    /* color: #e9e9e9; */
    transition: 0.3s ease-out;
}

.footer-email-form h2 {
    margin-bottom: 32px;
}

.footer-input::placeholder {
    color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
    color: #262626;
    font-size: 24px;
}

.social-media {
    /* max-width: 1000px; */
    width: 100%;
}

.social-media-wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    /* width: 90%; */
    /* max-width: 1000px; */
    /* margin: 40px auto 0 auto; */
    margin: 0 4rem 0 4rem;
}

.social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
    margin-left: 2rem;
}

.social-logo {
    color: #262626;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-self: center;
    margin-bottom: 16px;
    align-items: center;
}

.website-rights {
    color: #262626;
    margin-bottom: 16px;
}

@media screen and (max-width: 640px) {
    .footer-links {
        padding-top: 2rem;
    }

    .footer-input {
        width: 100%;
    }

    .btn {
        width: 100%;
    }

    .footer-link-wrapper {
        /* flex-direction: column; */
        padding: 0 2rem 0 2rem;
    }

    .social-media-wrapper {
        flex-direction: column;
    }
}