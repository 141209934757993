:root {
    --primary: #3164F4;
    --highlight: #1951F2;
}

.btn {
    padding: 8px 20px;
    border-radius: 12px;
    outline: none;
    border: none;
    cursor: pointer;
}
  
.btn--primary {
    background-color: var(--primary);
    color: #fff;
    border: 1px solid var(--primary);
}

.btn--primary:hover {
    background-color: var(--highlight);
}
  
.btn--outline {
    background-color: transparent;
    color: #262626;
    padding: 8px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
}
  
.btn--medium {
    padding: 0.65rem 1rem;
    font-size: 1rem;
}
  
.btn--large {
    padding: 0.65rem 1.5rem;
    font-size: 1rem;
}
  
.btn--large:hover,
.btn--medium:hover {
    transition: all 0.3s ease-out;
    /* background: #fff; */
    /* color: #242424; */
    transition: 250ms;
}
  