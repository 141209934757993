.service-container {
    display: flex;
    width: 100%;
    flex-direction: column;
}

/* .service-container::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  } */

.chat-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 3rem 4rem;
    
    background-image: url('../../images/bg/service_bg.webp');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 40px;
    z-index: 2;
    gap: 64px;
}

.service-introduction-container {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    align-items: center;
}

.service-introduction-container h1 {
    /* justify-content: start; */
    text-align: start;
    color: #fff;
}

.service-introduction-container p {
    /* justify-content: start; */
    text-align: start;
    color: #fff;
}

.chat-thread-container {
    display: flex;
    flex-direction: column;
}

.chat-thread-container .image-view .image-wrapper-item {
    width: 420px;
}


@media screen and (max-width: 640px) {
    .service-container .chat-thread-container {
        display: none;
    }
}

.service-list-container {
    display: flex;
    flex-direction: column;
    padding: 3rem 4rem;
    justify-content: start;
}

.service-list-container .service-list .title-subtitle-container {
    margin: 30px 0px;
}

.service-list-container .service-list .title-subtitle-container h1 {
    text-align: start;
    /* font-size: 40px; */
}

.service-list-container .service-list .title-subtitle-container p {
    text-align: start;
    /* font-size: 20px; */
}

.service-list {
    list-style-type: none;
}

.chat-thread-container .imageview--half {
    border-radius: 50px;
}