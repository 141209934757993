video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.hero-container {
    /* background: url('../images/img-home.jpg') center center/cover no-repeat; */
    background-color: #ededed;
    /* height: 80vh; */
    /* width: 100%; */
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
    object-fit: contain;
    margin: 1rem 4rem;
}

.hero-text-container {
    display: flex;
    flex-direction: column;
    /* width: 50%; */
    align-items: center;
    object-fit: contain;
    gap: 12px;
}

.hero-container h1 {
    color: #030303;
    font-size: 2rem;
}

.hero-container p {
    margin-top: 8px;
    color: #030303;
    font-size: 1.2rem;
    font-family: 'Trebuchet MS', 'Lucide SansUnicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
    margin-top: 1rem;
}

.hero-btns .btn {
    /* margin: 6px; */
    width: 200px;
}

.fa-play-circle {
    margin-left: 4px;
}

.image-container {
    margin-top: 1.5rem;
    /* width: 50%; */
    display: flex;
}

.image-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    /* padding-top: 67%; */
    overflow: hidden;
}

.image-wrapper-fg {
    display: flex;
    justify-content: center;
}

.image-wrapper-fg-item {
    /* position: absolute; */
    /* top: 0;
    right: 0;
    bottom: 0;
    left: 0; */
    display: block;
    width: 100%;
    /* height: 40vh; */
    object-fit: fill;
    transition: all 0.2s linear;
    border-radius: 1rem;
  }

.image-wrapper-qrcode-container {
    margin-top: 1.5rem;
    /* width: 50%; */
    display: flex;
    flex-direction: column;
    /* width: 50%; */
    width: 400px;
    gap: 1rem;
}

.image-wrapper-qrcode-container-store-label {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    /* padding-top: 67%; */
    overflow: hidden;
}

.image-wrapper-qrcode-container-store-label-fg {
    display: flex;
    justify-content: center;
    width: 60%;
}

.image-wrapper-qrcode-container-store-label-item {
    /* position: absolute; */
    /* top: 0;
    right: 0;
    bottom: 0;
    left: 0; */
    display: block;
    width: 100%;
    /* height: 40vh; */
    object-fit: fill;
    transition: all 0.2s linear;
    border-radius: 1rem;
  }

.image-wrapper-qrcode-container-qrcode {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    /* padding-top: 67%; */
    overflow: hidden;
}

.image-wrapper-qrcode-container-qrcode-fg {
    display: flex;
    justify-content: center;
    width: 50%;
}

.image-wrapper-qrcode-container-qrcode-item {
    /* position: absolute; */
    /* top: 0;
    right: 0;
    bottom: 0;
    left: 0; */
    display: block;
    width: 100%;
    /* height: 40vh; */
    object-fit: fill;
    transition: all 0.2s linear;
    border-radius: 1rem;
}

@media screen and (max-width: 640px) {

    .hero-container {
        flex-direction: column;
    }

    /* .hero-container p {
        font-size: 20px;
    } */

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }

    .image-wrapper-qrcode-container-qrcode {
        display: none;
    }
}